import React, {Component} from 'react';
import dynamic from 'next/dynamic';
import Block from '../../adapters/helpers/Block'
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';
import SlideToggle from '../ReactSlideToggle/ReactSlideToggle.umd'
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { MainMenuConstants } from '../../adapters/helpers/Constants';
import {Label} from '../Label/Label';
import { stripHtml, stringToCapitalize } from '../../adapters/helpers/Utils';
const Icon = dynamic(() => import('../Icon/Icon'));
const Image = dynamic(() => import('../Image/Image'));

class MainMenu extends Component {

    _closedSubNavState = '';
    _desktopBreakpoint = '(max-width: 991px)';

    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.insideMenuRef = React.createRef();
        this.subMenuRef = React.createRef();
        this.mobileMenuRef = React.createRef();
        this.openMobileMenuRef = React.createRef();
        this.innerMenuRef = React.createRef();
        this.primaryColor = MainMenuConstants.primaryColor;

        this.state = {
            isMainMenuOpen: false,
            currentSubNav: this._closedSubNavState,
            intervalId: 0,
            lastScroll: 0,
            pathName: '',
            isPageLoaded: false,
            isLoadSubNav: false
        };

        this.handleToggleSubNavigation = this.handleToggleSubNavigation.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleToggleMainMenu = this.handleToggleMainMenu.bind(this);
        this.handleMenuHeight = this.handleMenuHeight.bind(this);
        this.toggleScroll = this.toggleScroll.bind(this);
        this.handleEscKeyPress = this.handleEscKeyPress.bind( this );
        this.handleStickybar = this.handleStickybar.bind(this);
        this.firstLink = null;
        this.lastLink = null;
    }

    componentDidMount() {
        window.addEventListener(MainMenuConstants.mousedown, this.handleClickOutside);
        window.addEventListener(MainMenuConstants.resize, this.handleResizeThrottled);
        window.addEventListener(MainMenuConstants.scroll, this.handleScrollThrottled);
        window.addEventListener(MainMenuConstants.keydown, this.handleEscKeyPress);
        this.setState({pathName: window.location.pathname})
        this.handleScroll();
        this.handleResize();
        this.handleStickybar();
        this.setState({isPageLoaded: true})
    }

    componentWillUnmount() {
        window.removeEventListener(MainMenuConstants.mousedown, this.handleClickOutside);
        window.removeEventListener(MainMenuConstants.resize, this.handleResizeThrottled);
        window.removeEventListener(MainMenuConstants.scroll, this.handleScrollThrottled);
        window.removeEventListener(MainMenuConstants.keydown, this.handleEscKeyPress);

        if (this.lastLink) {
            this.lastLink.removeEventListener(MainMenuConstants.blur, this.handleMobileLastItemBlur);
        }
    }

    handleStickybar() {
        let announcementEle = document.querySelector('.ob-announcement-bar');
        let mainEle = document.getElementById('main');
        announcementEle ? mainEle.classList.remove('ob-main-layout-align') : mainEle.classList.add('ob-main-layout-align');     
        mainEle.classList.remove( 'ob-main-layout-align-padding' );   
        if (!announcementEle) {
            const layout = document.querySelector('.layout:not(.ob-spotlight-layout)');
            layout.classList.add('layout-announcement-bar-hidden')
        }
    }

    handleResize() {
        if (this.openMobileMenuRef?.current && this.state.isMainMenuOpen) {
            this.openMobileMenuRef.current.onToggle();
        }

        this.setState({
            currentSubNav: this._closedSubNavState,
            isMainMenuOpen: false,
        }, () => {
            this.toggleScroll();
        });
    }

    handleScroll() {
        if (!document.body.classList.contains('noScroll')) {
            this.setState({
                lastScroll: window.pageYOffset
            })
        }
    }

    handleEscKeyPress(event) {
        if (event.key === MainMenuConstants.escape) {
            this.setState({
                currentSubNav: this._closedSubNavState,
            }, this.handleAfterEscKeyPress)
        }
    }

    handleAfterEscKeyPress() {
        this.toggleScroll();
        this.addBlurListenerOnLastLink();
    }

    addBlurListenerOnLastLink() {
        let mobileMenu = document.querySelector('.ob-main-menu--mobile');
        if (mobileMenu) {
            let links = mobileMenu.getElementsByTagName('a');
            if (links?.length > 0) {
                this.firstLink = links[0];
                this.lastLink = links[links.length-1];
                this.lastLink.addEventListener(MainMenuConstants.blur, this.handleMobileLastItemBlur.bind(this));
            }
        }
    }

    handleMobileLastItemBlur(event) {
        if (this.firstLink) {
            event.preventDefault();
            event.stopPropagation();
            this.firstLink.focus();
        }
    }

    toggleScroll() {
        if (this.state.isMainMenuOpen || this.state.currentSubNav !== this._closedSubNavState) {
            document.body.classList.add('noScroll');
            document.body.style.top = `-${this.state.lastScroll}px`;
            document.body.style.height = `-${this.state.lastScroll}px`;
        } else {
            document.body.classList.remove('noScroll');
            document.body.style.top = null;
            document.body.style.height = null;
            window.scrollTo(0, this.state.lastScroll);
        }
    }

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);

    handleScrollThrottled = throttle(this.handleScroll.bind(this), 100);

    handleClickOutside(event) {
        if (this.insideMenuRef.current) {
            if (!this.insideMenuRef.current.contains(event.target)) {
                this.setState({currentSubNav: this._closedSubNavState});
            }
        }
    }

    handleToggleSubNavigation(event) {
        const targetElemId = event.currentTarget.dataset.childId;

        if (targetElemId) {
            if (this.state.currentSubNav === targetElemId) {
                this.setState({currentSubNav: this._closedSubNavState, isLoadSubNav: true}, this.toggleScroll);
            } else {
                this.setState({currentSubNav: targetElemId, isLoadSubNav: true}, () => {
                    this.toggleScroll();
                    const subNav = this.subMenuRef.current;
                    if(subNav) {
                        const announcementBar = document.querySelector('.ob-announcement-bar');
                        if (announcementBar && announcementBar.style.display !== 'none') {
                            subNav.style.height = `calc(${window.innerHeight}px - ${subNav.offsetTop + announcementBar.offsetHeight}px)`;
                        } else {
                            subNav.style.height = `calc(${window.innerHeight}px - ${subNav.offsetTop}px)`;
                        }
                    }
                });
            }
        } else {
            this.setState({currentSubNav: this._closedSubNavState, isLoadSubNav: true}, this.toggleScroll);
        }
    }

    handleToggleMainMenu() {
        if (this.props.isLarge && !this.state.isMainMenuOpen) {
            this.addBlurListenerOnLastLink();
        }

        this.setState({
            isMainMenuOpen: !this.state.isMainMenuOpen,
            currentSubNav: this._closedSubNavState,
        }, this.toggleScroll);
    }

  handleMenuHeight() {
        const innerMenu = document.querySelector('.ob-menu-collapsible');
        const announcementBar = document.querySelector('.ob-announcement-bar');
        document.body.classList.add('noScroll');
        if(innerMenu && innerMenu.style) {
            if (announcementBar && announcementBar.style.display !== 'none') {
                innerMenu.style.height = (window.innerHeight - innerMenu.offsetTop + announcementBar.offsetHeight) + 'px'
            } else {
                innerMenu.style.height = (window.innerHeight - innerMenu.offsetTop)+'px';
            }
        }
    }

    renderCloseSubNavBtn() {
        return (
            <li className='ob-main-menu__subNav--close-btn'>
                <button aria-label={MainMenuConstants.closeMainMenuAriaLabel} className='event_close_window' onClick={ event => { this.handleToggleSubNavigation(event) } }>
                    <Icon name={MainMenuConstants.close} size={3} color={this.primaryColor} />
                    <span className='visuallyhidden'>{MainMenuConstants.Close}</span>
                </button>
            </li>
        )
    }

    renderHierarchyTitle(hierarchy, title, callback = null, toggleState = null) {
        const level = HierarchyUtils.getLevel(hierarchy);
        const childId = HierarchyUtils.getHierarchyId(hierarchy);
        const type = hierarchy.fields.type;

        if (level === MainMenuConstants.child
            && type !== MainMenuConstants.productByNeed
            && type !== MainMenuConstants.productByType
            && type !== MainMenuConstants.whyOralB
            && type !== MainMenuConstants.conditions
            && type !== MainMenuConstants.lifeStages
            && type !== MainMenuConstants.ourAmbition  ) {
            return (
                <button
                    className={`event_menu_click ob-main-menu__subNav--btn link-button${this.state.currentSubNav === childId || this.checkIfActive(hierarchy?.fields?.destinations) ? ' is-active' : ''}`}
                    data-action-detail={`${MainMenuConstants.Open} ${stripHtml(title)} ${MainMenuConstants.panel}`}
                    aria-label={`${stringToCapitalize(stripHtml(title))} ${MainMenuConstants.pullDown} ${this.state.currentSubNav === childId ? MainMenuConstants.expanded : MainMenuConstants.collapsed}`}
                    data-child-id={childId}
                    onClick={(event) => {
                        this.handleToggleSubNavigation(event)
                    }}
                >
                    {this.props.isLarge && hierarchy.fields.mainAsset ? (
                        <span className='ob-main-menu__subNav--btn-ctn'>
                            <span className='ob-main-menu__subNav--btn-img'>
                                <Image image={hierarchy.fields.mainAsset} />
                            </span>
                            <div className='ob-main-menu__subNav--btn-content'>
                                <span className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: title}}></span>
                                <span className='ob-main-menu__subNav--btn-icon'>
                                    <Icon name='chevronRight' size='1.3' color={this.primaryColor} arialable={MainMenuConstants.downArrow}/>
                                </span>
                            </div>
                        </span>
                    ) : (
                        <span>
                            {this.state.currentSubNav === childId || this.checkIfActive(hierarchy?.fields?.destinations) ? 
                               <span aria-current={'page'} className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: title}}></span> :
                               <span className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: title}}></span>
                            }
                            <span className='ob-main-menu__subNav--btn-icon'>
                                {this.props.isLarge ? (
                                    <Icon name={MainMenuConstants.wideChevronDown} size='0.8' rotate={-90} color='#3D3D41' arialable={MainMenuConstants.downArrow} />
                                    ) : (
                                        <Icon name={MainMenuConstants.chevronDown} size='1.3' rotate={90} color={this.primaryColor} arialable={MainMenuConstants.downArrow} />
                                    )}
                            </span>
                        </span>
                    )
                    }
                </button>
            );
        } else {
            return (
                <div>
                    <h2 className='ob-sub-navigation__title ob-sub-navigation__title--desktop' dangerouslySetInnerHTML={{__html: title}}></h2>
                    <button aria-expanded={toggleState?.toLowerCase() === MainMenuConstants.expanding || toggleState?.toLowerCase() === MainMenuConstants.expanded ? true : false} onClick={callback} className='ob-sub-navigation__title ob-sub-navigation__title--mobile'>
                        <span dangerouslySetInnerHTML={{__html: title}}></span>
                        <span>
                            {toggleState?.toLowerCase() === MainMenuConstants.expanding || toggleState?.toLowerCase() === MainMenuConstants.expanded ? (
                                <Icon name={MainMenuConstants.minus6} viewBox={10} size='1' color='#000000'  />
                            ) : (
                                <Icon name={MainMenuConstants.plus} viewBox={10} size='1' color='#000000'  />
                            )}
                        </span>
                    </button>
                </div>
            );
        }
    }

    getHierarchyProperties(hierarchy) {
        const destinations = hierarchy.fields.destinations;
        const title = hierarchy.fields.title;
        const hierarchyId = HierarchyUtils.getHierarchyId(hierarchy);
        const isSubNav = hierarchy.fields.type === 'product-by-need' || hierarchy.fields.type === 'product-by-type' || hierarchy.fields.type === 'why-oral-b' || hierarchy.fields.type === 'conditions' || hierarchy.fields.type === 'life-stages' || hierarchy.fields.type === 'our-ambition';
        const isMainMenu = hierarchyId === 'Hierarchy---Main-Menu';
        const isSubNavCtn = !isMainMenu && !isSubNav;

        let itemClassNames = !isSubNav ? 'ob-main-menu__list--item' : 'ob-sub-navigation--item';
        let mainMenuClassNames = isMainMenu ? 'ob-main-menu' : '';
        let subNavigationListClassNames = isSubNav ? 'ob-sub-navigation__list' : '';
        let iconPosition = null;
        const mainMenuListClassNames = isSubNavCtn ? 'ob-main-menu__subNav--ctn' : '';
        const listClassNames = `ob-main-menu__list ${mainMenuListClassNames}`;
        const onlyShowOnClassNames = hierarchy.fields.onlyShowOn ? `ob-main-menu__list--${hierarchy.fields.onlyShowOn}` : '';

        if (isSubNavCtn) {
            itemClassNames = ' ob-sub-navigation';
            iconPosition = 'after';
        }

        return {
            destinations,
            title,
            hierarchyId,
            isSubNav,
            isMainMenu,
            isSubNavCtn,
            itemClassNames,
            mainMenuClassNames,
            subNavigationListClassNames,
            iconPosition,
            mainMenuListClassNames,
            listClassNames,
            onlyShowOnClassNames
        };
    }

    renderHierarchy(hierarchy) {
        {
            return this.state.isPageLoaded ?
                (this.props.isLarge || this.props.isLarge == null) ? this.renderMobileHierarchy(hierarchy) : this.renderDesktopHierarchy(hierarchy)
            :
                <React.Fragment><div className='ob-main-menu__mobile__div'>{this.renderMobileHierarchy(hierarchy)}</div><div className='ob-main-menu__desktop__div'>{this.renderDesktopHierarchy(hierarchy)}</div></React.Fragment>
        }
    }

    checkIfActive(menuItem) {
        let isActive = false;
        if (this.state.pathName !== '') {
            if (Array.isArray(menuItem)) {
                menuItem.forEach((destination) => {
                    if (destination?.fields?.pagePath) {
                        if (destination?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                            isActive = true;
                        }
                    } else if (destination?.fields?.destinations) {
                        destination?.fields?.destinations.forEach((elem) => {
                            if (elem?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                                isActive = true;
                            }
                        })
                    }
                })
            } else {
                if (menuItem?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                    isActive = true;
                }
            }
        }

        return isActive;
    }

    renderMobileHierarchy(hierarchy) {
        const properties = this.getHierarchyProperties(hierarchy);

        const closeMenuLabel = Label({label: this.block.getFieldValue(MainMenuConstants.closeMenuLabel)});
        const openMenuLabel = Label({label: this.block.getFieldValue(MainMenuConstants.openMenuLabel)});
        const returnToMainMenuLabel = Label({label: this.block.getFieldValue(MainMenuConstants.returnToMainMenuLabel)});

        return (
            <div className={'ob-main-menu--mobile'}>
                <SlideToggle
                    duration={500}
                    collapsed={properties.isMainMenu && !this.state.isMainMenuOpen}
                    ref={this.openMobileMenuRef}
                    range
                    onExpanding={() => {
                        this.handleToggleMainMenu();
                    }}
                    onCollapsing={() => {
                        this.handleToggleMainMenu();
                    }}
                    onExpanded={() => {
                        this.handleMenuHeight();
                    }}
                    >
                    {({onToggle, setCollapsibleElement, range}) => (
                        <div ref={this.innerMenuRef}
                            className={`${properties.mainMenuClassNames} ${properties.onlyShowOnClassNames} ${properties.subNavigationListClassNames}`}>
                            {this.state.isMainMenuOpen && properties.isMainMenu && this.state.currentSubNav !== this._closedSubNavState &&
                            <button className={'ob-main-menu__return-btn'} onClick={event => {this.handleToggleSubNavigation(event)}}>
                                <Icon name={MainMenuConstants.arrowLeft} size="2"/>
                                <span className={'visuallyhidden'}>{returnToMainMenuLabel}</span>
                            </button>
                            }
                            {properties.isMainMenu &&
                            <div className={'ob-main-menu__logo ob-main-menu__logo--mobile'}>
                                {HierarchyUtils.renderDestination(hierarchy.fields.destinations[0], this.renderHierarchy.bind(this, hierarchy.fields.destinations[0]))}
                            </div>
                            }
                            {properties.isMainMenu &&
                                <div className={'ob-main-menu__open-ctn'}>
                                    <button onClick={onToggle}
                                            className='event_menu_click'
                                            aria-expanded={this.state.isMainMenuOpen}
                                            data-action-detail={this.state.isMainMenuOpen ? closeMenuLabel : openMenuLabel}>
                                        <span>
                                            <Icon name={this.state.isMainMenuOpen ? MainMenuConstants.close : MainMenuConstants.hamburger} size="2" color={this.primaryColor}/>
                                            <span className={'visuallyhidden'}>{this.state.isMainMenuOpen ? closeMenuLabel : openMenuLabel}</span>
                                        </span>
                                    </button>
                                </div>
                            }
                            {properties.title && !properties.destinations && this.renderHierarchyTitle(hierarchy, properties.title)}
                            {properties.destinations && !properties.isSubNav &&
                            <div ref={setCollapsibleElement} className={'ob-menu-collapsible'}>
                                {properties.title && this.renderHierarchyTitle(hierarchy, properties.title)}
                                <div ref={properties.isMainMenu && this.mobileMenuRef}>
                                    <ul ref={properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId && this.subMenuRef}
                                        id={properties.hierarchyId}
                                        className={`${properties.listClassNames}${this.state.currentSubNav === properties.hierarchyId ? ' is-active' : ''}`}
                                        style={range ? {opacity: Math.max(0, range)} : {opacity: 1}}>
                                        {
                                            this.state.isMainMenuOpen && this.renderDestination(properties, 'chevronRight')
                                        }
                                    </ul>
                                </div>
                            </div>
                            }
                            {properties.destinations && properties.isSubNav &&
                            <SlideToggle
                                duration={500}
                                collapsed={properties.hierarchyId !== 'Hierarchy---More-Products---By-Type'}>
                                {({onToggle, setCollapsibleElement, range, toggleState}) => (
                                    <div>
                                        {properties.title && this.renderHierarchyTitle(hierarchy, properties.title, onToggle, toggleState)}
                                        <ul className={'ob-sub-navigation__list'} ref={setCollapsibleElement}
                                            style={range ? {opacity: Math.max(0, range)} : {opacity: 1}}>
                                            {
                                                this.renderDestination(properties,'chevronRight')
                                            }
                                        </ul>
                                    </div>
                                )}
                            </SlideToggle>
                            }
                        </div>
                    )}
                </SlideToggle>
            </div>
        );
    }

    renderDesktopHierarchy(hierarchy) {
        const properties = this.getHierarchyProperties(hierarchy);

        return (
            <div className={`${properties.mainMenuClassNames} ${properties.onlyShowOnClassNames} ${properties.subNavigationListClassNames}`}>
                {properties.title && !properties.destinations && this.renderHierarchyTitle(hierarchy, properties.title)}
                {properties.destinations &&
                <div className={properties.isMainMenu ? 'ob-main-menu__ctn' : ''}>
                    {properties.isMainMenu && properties.destinations &&
                        <div className={'ob-main-menu__logo ob-main-menu__logo--desktop'}>
                            {HierarchyUtils.renderDestination(hierarchy.fields.destinations[0], this.renderHierarchy.bind(this, hierarchy.fields.destinations[0]))}
                        </div>
                    }
                    {properties.title && this.renderHierarchyTitle(hierarchy, properties.title)}
                    {properties.isMainMenu ? (
                        <ul ref={properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId && this.subMenuRef}
                            id={properties.hierarchyId}
                            className={`${properties.listClassNames}${this.state.currentSubNav === properties.hierarchyId ? ' is-active' : ''}`}>
                            {properties.destinations.map((dest, index) => (
                                dest.fields.contentType !== 'languageSelectionBlock' && dest.fields.contentType !== 'loginNavigationBlock' && index !== 0 &&
                                <li className={`${properties.itemClassNames}${dest.fields.className ? ' ' + dest.fields.className : ''}${!dest.fields.image && !properties.isMainMenu ? ' no-img' : ''}`}
                                    key={index} data-is-logo={properties.isMainMenu && index === 0}
                                    data-is-language-selector={dest.fields.contentType === 'languageSelectionBlock'}
                                    data-is-login-navigation-block={dest.fields.contentType === 'loginNavigationBlock'}>
                                    {
                                        dest.fields.image && !properties.isMainMenu ?
                                            HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), {
                                                name: 'ovalArrowRight',
                                                position: 'before'
                                            }) :
                                            HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), null, null, this.checkIfActive(dest) ? ' is-active' : null)
                                    }
                                </li>
                            ))}
                            {properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId &&
                            this.renderCloseSubNavBtn()
                            }
                        </ul>
                    ) : this.state.isLoadSubNav ? (
                        <div className={`${properties.listClassNames} ${this.state.currentSubNav === properties.hierarchyId ? 'is-active' : ''}`}
                            ref={properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId && this.subMenuRef}>
                            <ul id={properties.hierarchyId}
                                className={`ob-main-menu__subNav-list ${this.state.currentSubNav === properties.hierarchyId ? ' is-active' : ''}
                                            ${properties.destinations.length === 3 ? 'ob-learn-section-length-3' : 'ob-learn-section-length-4'}`}>
                                {properties.destinations.map((dest, index) => (
                                    <li className={`${properties.itemClassNames}${dest.fields.className ? ' ' + dest.fields.className : ''}${!dest.fields.image && !properties.isMainMenu ? ' no-img' : ''}`}
                                        key={index} data-is-logo={properties.isMainMenu && index === 0}
                                        data-is-language-selector={dest.fields.contentType === 'languageSelectionBlock'}
                                        data-is-login-navigation-block={dest.fields.contentType === 'loginNavigationBlock'}>
                                        {
                                            dest.fields.image && !properties.isMainMenu ?
                                                HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), {
                                                    name: 'ovalArrowRight',
                                                    position: 'before'
                                                }) :
                                                HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest))
                                        }
                                    </li>
                                ))}
                                {properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId &&
                                this.renderCloseSubNavBtn()
                                }
                            </ul>
                        </div>
                    ) : null
                    }

                </div>
                }
            </div>
        )
    }

    renderDestination(properties, iconName = '') {
        const icon = {
            name: iconName,
            position: properties.iconPosition,
        };

        return properties.destinations.map((dest, index) => (
            <li className={`${properties.itemClassNames}${dest.fields.className ? ' ' + dest.fields.className : ''}${!dest.fields.image && !dest.fields.mainAsset ? ' no-img' : ''}${dest.fields.contentType === 'loginNavigationBlock' ? ' ob-main-menu__login-navigation' : ''}`}
                key={index} data-is-logo={properties.isMainMenu && index === 0}>
                {HierarchyUtils.renderDestination(dest, this.renderHierarchy.bind(this, dest), icon)}
            </li>
        ))
    }

    render() {
        const className = `main-menu ${this.block.getFieldValue(MainMenuConstants.classNames) !== undefined ? this.block.getFieldValue(MainMenuConstants.classNames) : ''}`;
        const hierarchyDoc = this.block.getFieldValue(MainMenuConstants.hierarchy);
        const hierarchyImage = hierarchyDoc.fields.mainAsset;
        const mainMenuLabel = Label({label: this.block.getFieldValue(MainMenuConstants.mainMenuLabel)});

        return (
            <nav aria-label={mainMenuLabel} ref={this.insideMenuRef} role='navigation' className={`${className} ${this.state.isMainMenuOpen ? 'is-active' : ''}`}>
                {this.renderHierarchy(hierarchyDoc)}
                <Image image={hierarchyImage}></Image>
            </nav>
        )
    }
}

export default mediaQueryHOC(MainMenu);
// This export is for unit testing (do not remove) :
export const MainMenuTest = MainMenu;

MainMenu.propTypes = {
    isLarge: PropTypes.bool,
};
